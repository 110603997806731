<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import {getColorSquares} from "./utils"

    export let entry: FormEntry

    const squares = 15 * 4
    const colors = getColorSquares(squares)
</script>


{#if !(entry.disabled && entry.value === "")}
    <div {...entry.rowAttr} class="flex flex-col">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}

        <input
                {...entry.attr}
                autocomplete="off"
                tabindex="-1"
                disabled={entry.disabled ? 'disabled' : null}
                id={entry.id}
                name={entry.fullName}
                on:input={e => entry.value = e.target.value}
                on:input
                required={entry.required ? 'required' : null}
                type="hidden"
                value={entry.value}
        />

        <div class="flex flex-wrap gap-[.48rem] w-96 mt-1">
            {#each [...Array(squares)] as _, index (index)}
                <div on:click|preventDefault={() => entry.value = colors[index]}
                        style="scale: 1; background-color: {colors[index]};"
                        class="w-[1.15rem] h-[1.15rem] rounded-[4px] {!entry.disabled ? index % 2 === 0 ? 'hover:scale-125 cursor-pointer hover:rotate-45' : 'hover:scale-125 cursor-pointer hover:-rotate-45' : ''} transition-all {entry.value === colors[index] ? 'rotate-45 scale-110' : ''}"
                ></div>
            {/each}
        </div>

        <FormTypeErrors errors={entry.errors}/>
    </div>
{/if}