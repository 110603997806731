let cache: Record<number, Record<number, string>> = {}

const hslToHex = (h: number, s: number, l: number): string => {
    l /= 100
    const a = s * Math.min(l, 1 - l) / 100
    const f = (n: number) => {
        const k = (n + h / 30) % 12
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
        return Math.round(255 * color).toString(16).padStart(2, '0')
    }
    
    return `#${f(0)}${f(8)}${f(4)}`
}

export const getColorSquares = (count: number): Record<number, string> => {
    if (cache[count]) {
        return cache[count]
    }
    
    cache[count] = {}
    
    for (let i = 0; i < count; i++) {
        cache[count][i] = hslToHex(
            Math.round((360 / count) * i),
            80,
            55
        )
    }
    
    return cache[count]
}
